import { create } from 'zustand';

import { createSelectors } from '~~/store/selector';

import type { ResultData } from './result.entity';

interface ResultState {
  results: ResultData | null;
  setResults: (resultData: ResultData | null) => void;

  totalScore: number;
}

const resultZustand = create<ResultState>()(
  (set) => ({
    results: null,

    setResults: (resultData: ResultData | null) => {
      set((state) => {
        if (!resultData) {
          state.results = null;
          state.totalScore = 0;

          return state;
        }

        state.results = resultData;
        state.totalScore = Object.values(resultData.calculatedResult).reduce(
          (prev, curr) => prev + curr,
          0,
        );

        return state;
      });
    },

    totalScore: 0,
  }),
);

export const useResultStore = createSelectors(resultZustand);

export enum ROUTE_PATHS {
  ACTIONS = 'actions',
  DASHBOARD = 'dashboard',
  FORGOT_PASSWORD = 'forgot-password',
  GOALS = 'goals',
  GROUP_SUBSCRIPTION = 'group-subscription',
  GROUPS = 'groups',
  LOGIN = 'login',
  MAIL_SEND = 'mail-sent',
  NEW_PASSWORD = 'new-password',
  PROFILE = 'profile',
  QUESTIONS = 'questions',
  RESET_PASSWORD = 'reset-password',
  RESULTS = 'results',
  ROOT = '/',
  TOP_10 = 'top-10',
  VERIFY_EMAIL = 'verify-email',
}

export enum CRUD_PATH {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum GROUP_REQUEST_PATH {
  REQUEST = 'request',
  REQUEST_OPEN_GROUP = 'request-open-group',
  REQUEST_SEND = 'request-send',
}

import type { ResultData } from '~~/app/result/result.entity';

import { GoalRepository } from '~~/app/goals-groups/goal.repository';
import { GroupRepository } from '~~/app/goals-groups/group.repository';
import { NewsletterRepository } from '~~/app/user/newsletter.repository';
import { UserRepository } from '~~/app/user/user.repository';
import { BaseRepository } from '~~/firebase/repositories/repository-factory';

import { StorageRepository } from './storage.repository';

export const userRepo = new UserRepository();
export const resultRepo = new BaseRepository<ResultData>('results');
export const groupRepo = new GroupRepository();
export const goalRepo = new GoalRepository();
export const newsletterRepo = new NewsletterRepository();
export const storageRepo = new StorageRepository();

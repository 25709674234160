import { Timestamp, deleteDoc, updateDoc } from 'firebase/firestore';

import { storageRepo, userRepo } from '~~/firebase/repositories';
import { BaseRepository } from '~~/firebase/repositories/repository-factory';

import type { ResultCalculation } from '../result/result.entity';
import type { GoalData } from './goal.entity';

export class GoalRepository extends BaseRepository<GoalData> {
  constructor() {
    super('goals');
  }

  async createGoal(goalName: string, earthsGoal: number, earthsResult: number, goalData: ResultCalculation, isGroupGoal: boolean = false) {
    const goalRef = this.getDocRef();

    const image = await storageRepo.getRandomImage();

    if (!image) {
      throw new Error('Failed at creating goal');
    }

    const goalPayload = {
      createdAt: Timestamp.now(),
      earthsGoal,
      earthsResult,
      goalData,
      goalName,
      image,
    };

    await this.setDocument({
      data: goalPayload,
      id: goalRef.id,
    });

    if (!isGroupGoal) {
      await userRepo.appendGoals(goalRef);
    }
    return { ...goalPayload, id: goalRef.id };
  }

  async deleteGoal(id: string) {
    const goalRef = this.getDocRef(id);

    await deleteDoc(goalRef);
  }

  async updateGoal(id: string, goalName: string, earthsGoal: number, earthsResult: number, goalData: ResultCalculation) {
    const goalRef = this.getDocRef(id);

    const goalPayload = {
      createdAt: Timestamp.now(),
      earthsGoal,
      earthsResult,
      goalData,
      goalName,
      id,
    };

    await updateDoc(goalRef, goalPayload);

    return goalPayload;
  }

  async updateGoalPicture(id: string, image: string) {
    const goalRef = this.getDocRef(id);

    await updateDoc(goalRef, {
      image,
    });
  }
}

import ReactGA from 'react-ga4';

import { analyticsAllowedToTrigger } from '~~/utils/environment-util';

export function triggerPageView({ historyAction, pathname }: { historyAction: string; pathname: string }) {
  if (historyAction === 'PUSH' && analyticsAllowedToTrigger()) {
    ReactGA.set({ page: pathname }); // Update the user's current page
    ReactGA.send({ hitType: 'pageview', page: pathname });
  }
}

export function triggerEventView({ action, category }: { action: string; category: string }) {
  if (analyticsAllowedToTrigger()) {
    ReactGA.event({
      action,
      category,
    });
  }
}


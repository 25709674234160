import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { firebaseAuth } from '~~/firebase';
import { createSelectors } from '~~/store/selector';

import type { GoalData } from '../goals-groups/goal.entity';
import type { ResultData } from '../result/result.entity';
import type { UserDoc } from './user.entity';

interface UserState {
  addUserAcceptedAction: (id: string) => void;
  addUserDeclinedAction: (id: string) => void;
  addUserGoal: (goals: GoalData) => void;
  addUserResult: (result: ResultData) => void;
  deleteUserAcceptedAction: (id: string) => void;
  deleteUserGoal: (id: string) => void;
  setUser: (user: UserDoc | null) => void;
  setUserAcceptedActions: (acceptedActions: string[]) => void;
  updateUserGoal: (id: string, goal: GoalData) => void;
  user: UserDoc | null;
}

const userZustand = create<UserState>()(
  immer(
    devtools(
      (set) => ({
        addUserAcceptedAction: (id: string) => {
          set((state) => {
            if (state.user) {
              state.user.acceptedActions = [...state.user.acceptedActions, id];
            }
          });
        },

        addUserDeclinedAction: (id: string) => {
          set((state) => {
            if (state.user) {
              state.user.declinedActions = [...state.user.declinedActions, id];
            }
          });
        },

        addUserGoal: (goals_: GoalData) => {
          set((state) => {
            if (state.user) {
              state.user.goals = [goals_, ...state.user.goals];
            }
          });
        },

        addUserResult: (result_: ResultData) => {
          set((state) => {
            if (state.user) {
              state.user.results = [result_, ...state.user.results];
            }
          });
        },

        deleteUserAcceptedAction: (id: string) => {
          set((state) => {
            if (state.user) {
              state.user.acceptedActions = state.user.acceptedActions.filter((actionId) => actionId !== id);
            }
          });
        },

        deleteUserGoal: (id: string) => {
          set((state) => {
            if (state.user) {
              state.user.goals = state.user.goals.filter((goal) => goal.id !== id);
            }
          });
        },

        setUser: (user_: UserDoc | null) => {
          set((state) => {
            state.user = user_;
          });
        },

        setUserAcceptedActions: (acceptedActions: string[]) => {
          set((state) => {
            if (state.user) {
              state.user.acceptedActions = acceptedActions;
            }
          });
        },

        updateUserGoal: (id: string, goal_: GoalData) => {
          set((state) => {
            if (state.user) {
              const otherGoal = [...state.user.goals.filter((goal) => goal.id !== id), goal_];
              otherGoal.sort((a, b) => {
                return a.createdAt.seconds - b.createdAt.seconds;
              });
              state.user.goals = otherGoal;
            }
          });
        },

        user: null,
      }),
      {
        enabled: import.meta.env.DEV,
      },
    ),
  ),
);

export const useUserStore = createSelectors(userZustand);

export function useUserComputed() {
  return {
    isLoggedIn: !!firebaseAuth.currentUser?.emailVerified,
  };
}

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import type { CompProps } from '~~/typings/props';

import { MhiButton } from '~~/design-system/components/mhi-button';
import {
  MhiDropdownMenu,
  MhiDropdownMenuContent,
  MhiDropdownMenuItem,
  MhiDropdownMenuTrigger,
} from '~~/design-system/components/mhi-dropdown-menu';

import { LayoutHeaderLinks } from './layout-header-links';

export function LayoutHeader({ className }: CompProps) {
  const [isVisible, setVisible] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const { i18n, t } = useTranslation('common');
  const [lang, setLang] = useState(i18n.language || 'en');

  useEffect(() => {
    if (i18n.language !== lang) {
      // eslint-disable-next-line ts/no-floating-promises
      i18n.changeLanguage(lang);
    }
  }, [i18n, lang]);

  const visibleNavbar = () => {
    if (window.scrollY >= 30) {
      return setVisible(true);
    }

    return setVisible(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', visibleNavbar);

    return () => {
      window.removeEventListener('scroll', visibleNavbar);
    };
  });

  const { pathname } = useLocation();
  const isHomePath = pathname === `/${i18n.language}`;

  const headerClasses = classNames(
    className,
    'fixed z-header w-full top-0 bg-white',
    {
      'md:translate-y-8': !isVisible && isHomePath,
      'sm:bg-white': isVisible,
      'transition-property-[background-color,transform] duration-280 sm:bg-transparent': isHomePath,
    },
  );

  const headerFlexClasses = classNames(
    'flex items-center justify-between w-full md:(pr-5 mx-auto) bg-white',
    {
      'max-w-[1213px] md:bg-transparent': isHomePath,
      'max-w-[1340px]': !isHomePath,
    },
  );

  const langOptions = [
    { label: t('header.language_nl'), value: 'nl' },
    { label: t('header.language_en'), value: 'en' },
  ];

  return (
    <header className={ headerClasses }>
      <div className={ headerFlexClasses }>
        <div className="flex items-center">
          <Link
            className="mx-5 mt-5 mb-4"
            target={ isHomePath ? '_blank' : undefined }
            to={ isHomePath ? 'https://thinkbigactnow.org/' : '/' }
          >
            <img
              alt="Mijn verborgen impact logo"
              className="w-13 h-6 md:(h-9 w-19.5)"
              onClick={ () => setOpenMenu(false) }
              src={ isHomePath ? '/images/img-logo-think-big-act-now.svg' : '/images/img-mijn-verborgen-impact-logo-text.svg' }
            />
          </Link>

          <MhiDropdownMenu modal={ false }>
            <MhiDropdownMenuTrigger asChild>
              <MhiButton
                className="transition-colors-280 color-primary rounded-1 mhi:text-sm-3 py-2 px-4 no-underline outline-none hover:(bg-white/50)"
                variant="outline"
              >
                { lang.toLocaleUpperCase() }
              </MhiButton>
            </MhiDropdownMenuTrigger>

            <MhiDropdownMenuContent className="mhi:(z-header p-0) color-primary text-size-sm-4 leading-10 divide-y-2 divide-white-darker bg-white w-[230px] rounded-2.5">
              <MhiDropdownMenu>
                { langOptions.map((item) => (
                  <MhiDropdownMenuItem
                    className={ classNames('px-5 h-10 hover:(cursor-pointer bg-white-darker) overflow-hidden text-left', {
                      'bg-white-darker font-500': lang === item.value,
                    }) }
                    key={ item.value }
                    onClick={ () => setLang(item.value) }
                  >
                    { item.label }
                  </MhiDropdownMenuItem>
                )) }
              </MhiDropdownMenu>
            </MhiDropdownMenuContent>
          </MhiDropdownMenu>
        </div>

        <LayoutHeaderLinks isOpen={ openMenu } setOpen={ setOpenMenu } />

        <i
          className="i-mhi-burger block mx-5 my-2 color-primary text-size-[18px] cursor-pointer md:hidden"
          onClick={ () => setOpenMenu(!openMenu) }
        />

      </div>
    </header>
  );
}

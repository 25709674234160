import type { UseMutationOptions } from '@tanstack/react-query';
import type { HttpsCallableOptions } from 'firebase/functions';

import { useMutation } from '@tanstack/react-query';
import { httpsCallable } from 'firebase/functions';

import { firebaseFunctions } from '~~/firebase';

export function useFunctionsCall<RequestData = any, ResponseData = unknown>(
  name: string,
  {
    mutationOptions,
    options,
  }: {
    mutationOptions?: UseMutationOptions<ResponseData, Error, RequestData>;
    options?: HttpsCallableOptions;
  } = {},
) {
  return useMutation<ResponseData, Error, RequestData>({
    mutationFn: async (data) => {
      const response = await httpsCallable<RequestData, ResponseData>(
        firebaseFunctions,
        name,
        options,
      )(data);

      return response.data;
    },

    ...mutationOptions,
  });
}

import type {
  CollectionReference,
  DocumentData,
  SetOptions,
  WithFieldValue,
} from 'firebase/firestore';

import {
  collection,
  doc as docRef,
  getDoc,
  setDoc,
} from 'firebase/firestore';

import { firestore } from '~~/firebase';

export class BaseRepository<T, D extends DocumentData = DocumentData> {
  collectionRef: CollectionReference<T, D>;

  constructor(collectionName: string) {
    this.collectionRef = collection(firestore, collectionName) as CollectionReference<T, D>;
  }

  getDocRef(id?: string) {
    if (!id) {
      return docRef<T, D>(this.collectionRef);
    }

    return docRef<T, D>(this.collectionRef, id);
  }

  async getDocument(id: string) {
    const document_ = await getDoc<T, D>(this.getDocRef(id));

    if (document_.exists()) {
      return document_.data();
    } else {
      return null;
    }
  }

  setDocument({
    data,
    id,
    options = {},
  }: {
    data: WithFieldValue<T>;
    id?: string;
    options?: SetOptions;
  }) {
    return setDoc(this.getDocRef(id), data, options);
  }
}

import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';

import { useCheckUser } from '~~/app/auth/hooks/use-auth-user';

import { CoreDialogCookie } from './app/core/components/core-dialog-cookie';
import { COOKIES_LOCAL_STORAGE } from './app/core/entities/storage.entity';
import { useLocalStorage } from './app/core/hooks';
import { useFetchTranslationsContent } from './i18n/hooks/use-fetch-contentful';
import { router } from './router';
import { analyticsAllowedToTrigger } from './utils/environment-util';

function App() {
  const [isAnalyticsTriggered, setIsAnalyticsTriggered] = useState(false);
  const [cookieLocal, _setCookieLocal] = useLocalStorage<boolean | undefined>(COOKIES_LOCAL_STORAGE, undefined);

  useEffect(() => {
    if (analyticsAllowedToTrigger() && !isAnalyticsTriggered) {
      ReactGA.initialize(import.meta.env.VITE_GA_TRACKING);
      setIsAnalyticsTriggered(true);
    }
  }, [cookieLocal, isAnalyticsTriggered]);

  useFetchTranslationsContent();

  const { i18n, t } = useTranslation('common');

  useCheckUser();

  return (
    <HelmetProvider>
      <Helmet>
        <title>{ t('title') }</title>

        <meta content={ t('description') } name="description" />

        <link href={ i18n.language === 'en' ? '/favicon-en.png' : '/favicon-nl.png' } rel="icon" type="image/png" />
      </Helmet>

      <RouterProvider router={ router } />

      <CoreDialogCookie />
    </HelmetProvider>
  );
}

export default App;

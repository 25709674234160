export enum QUESTION {
  BATHING = 'BATHING',
  CAR = 'CAR',
  CLOTHES = 'CLOTHES',
  DAIRY = 'DAIRY',
  FLYING = 'FLYING',
  FOOD = 'FOOD',
  HOUSING = 'HOUSING',
  MEAT = 'MEAT',
  POSSESSIONS = 'POSSESSIONS',
  PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT',
  USER = 'USER',
}

export type QuestionWithoutUser = Exclude<QUESTION, QUESTION.USER>;

export enum GENDER_TYPE {
  FEMALE = 'female',
  MALE = 'male',
  TRANS = 'X',
}

export enum QUESTION_MODE {
  PRECISE = 'precise',
  QUICK = 'quick',
}

export interface QuestionDataUser {
  gender?: GENDER_TYPE;
  year?: number | string;
  zipCode: string;
}

export interface QuestionDataFood {
  beer: number;
  calories: number;
  coffee: number;
  'drink-alco': number;
  'drink-no-alco': number;
  juice: number;
  liquor: number;
  soda: number;
  tea: number;
  water: number;
  wine: number;
}

export interface QuestionDataMeat {
  beef: number;
  chicken: number;
  fish: number;
  'meat-global': number;
  nlvsabroad: number;
  pork: number;
}

export interface QuestionDataDairy {
  cheese: number;
  dairy: number;
  eggs: number;
  milk: number;
}

export interface QuestionDataClothing {
  clothes: number;
  recycledcottonvswool: number;
  secondhand: number;
}

export interface QuestionDataPossession {
  books: number;
  digital: number;
  electric: number;
  other: number;
  possessions: number;
}

export interface QuestionDataHousing {
  KWH: number;
  building: string;
  duurzaam: string;
  electric: string;
  'energy-label': string;
  gas: number;
  house: number;
  'house-heating': string;
  'house-type': string;
  people: number;
}

export interface QuestionDataBathing {
  'bath': number;
  'heat-saving': boolean;
  'raining-shower': boolean;
  'saving-shower': boolean;
  'shower': number;
  'sun-water': boolean;
}

export interface QuestionDataCar {
  cars: number;
  distance: number;
  preciseItems: QuestionDataCarPrecise[];
}

export interface QuestionDataCarPrecise {
  'car-class': string;
  'car-fuel': string;
  'car-lifters': number;
  'car-made': string;
  'car-owner': number;
  'car-year': number;
  'distance-precise': number;
}

export interface QuestionDataFlying {
  flown: number;
  liftoff: number;
}

export interface QuestionDataPublicTransport {
  bus: number;
  metro: number;
  train: number;
  tram: number;
}

export type QuestionDataPayload = QuestionDataBathing | QuestionDataCar | QuestionDataClothing | QuestionDataDairy | QuestionDataFlying | QuestionDataFood | QuestionDataHousing | QuestionDataMeat | QuestionDataPossession | QuestionDataPublicTransport | QuestionDataUser;

export interface QuestionChangerProps {
  defaultValue: boolean | number | string;
  i18nKey: string;
  onChangeValue: (value: boolean | number | string) => void;
}

export interface QuestionDataResults {
  [QUESTION.BATHING]: QuestionDataBathing;
  [QUESTION.CAR]: QuestionDataCar;
  [QUESTION.CLOTHES]: QuestionDataClothing;
  [QUESTION.DAIRY]: QuestionDataDairy;
  [QUESTION.FLYING]: QuestionDataFlying;
  [QUESTION.FOOD]: QuestionDataFood;
  [QUESTION.HOUSING]: QuestionDataHousing;
  [QUESTION.MEAT]: QuestionDataMeat;
  [QUESTION.POSSESSIONS]: QuestionDataPossession;
  [QUESTION.PUBLIC_TRANSPORT]: QuestionDataPublicTransport;
  [QUESTION.USER]: QuestionDataUser;
}

export interface OptionData {
  id: string;
  label: string;
  value: string;
}

export interface TooltipData {
  end: number;
  id: string;
  label: string;
  start: number;
}

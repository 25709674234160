import type { RouteObject } from 'react-router-dom';

import { lazy } from 'react';
import { Navigate, redirect } from 'react-router-dom';

import { LayoutBase } from '~~/app/layouts/components/layout-base';
import { i18nInstance } from '~~/i18n';

import { CRUD_PATH, ROUTE_PATHS } from './entity.router';

const AuthLoggedin = lazy(() => import('~~/app/auth/components/auth-loggedin').then((module) => ({ default: module.AuthLoggedin })));

const AuthRequired = lazy(() => import('~~/app/auth/components/auth-required').then((module) => ({ default: module.AuthRequired })));

const GroupOwnerRequired = lazy(() => import('~~/app/goals-groups/components/group-owner-required').then((module) => ({ default: module.GroupOwnerRequired })));

const PageQuestionMapping = lazy(() => import('~~/app/question/pages/page-question-mapping').then((module) => ({ default: module.PageQuestionMapping })));

const PageActions = lazy(() => import('~~/app/actions/page-actions').then((module) => ({ default: module.PageActions })));

const PageForgotPassword = lazy(() => import('~~/app/auth/forgot-password/page-forgot-password').then((module) => ({ default: module.PageForgotPassword })));

const PageAuth = lazy(() => import('~~/app/auth/page-auth').then((module) => ({ default: module.PageAuth })));

const PageMailSent = lazy(() => import('~~/app/auth/page-mail-sent').then((module) => ({ default: module.PageMailSent })));

const PageNewPassword = lazy(() => import('~~/app/auth/page-new-password').then((module) => ({ default: module.PageNewPassword })));

const PageVerifyEmail = lazy(() => import('~~/app/auth/page-verify-email').then((module) => ({ default: module.PageVerifyEmail })));

const PageDashboard = lazy(() => import('~~/app/dashboard/page-dashboard').then((module) => ({ default: module.PageDashboard })));

const PageGoalDetail = lazy(() => import('~~/app/goals-groups/page-goal-detail').then((module) => ({ default: module.PageGoalDetail })));

const PageGoalsGroups = lazy(() => import('~~/app/goals-groups/page-goals-groups').then((module) => ({ default: module.PageGoalsGroups })));

const PageGroup = lazy(() => import('~~/app/goals-groups/page-group').then((module) => ({ default: module.PageGroup })));

const PageGroupCreate = lazy(() => import('~~/app/goals-groups/page-group-create').then((module) => ({ default: module.PageGroupCreate })));

const PageGroupSubscription = lazy(() => import('~~/app/goals-groups/page-group-subscription').then((module) => ({ default: module.PageGroupSubscription })));

const PageLanding = lazy(() => import('~~/app/landing/page-landing').then((module) => ({ default: module.PageLanding })));

const PageQuestion = lazy(() => import('~~/app/question/pages/page-question').then((module) => ({ default: module.PageQuestion })));

const PageResults = lazy(() => import('~~/app/result/page-results').then((module) => ({ default: module.PageResults })));

const PageTop10 = lazy(() => import('~~/app/result/page-top-10').then((module) => ({ default: module.PageTop10 })));

const PageUserProfile = lazy(() => import('~~/app/user/page-user-profile').then((module) => ({ default: module.PageUserProfile })));

export const routes: RouteObject[] = [
  {
    errorElement: <Navigate
      replace
      to={ ROUTE_PATHS.ROOT }
    />,
    id: 'root',
    loader: () => {
      return redirect(`/${i18nInstance.language}`);
    },
    path: ROUTE_PATHS.ROOT,
  },
  {
    Component: LayoutBase,

    children: [
      {
        Component: PageLanding,
        index: true,
      },

      // Auth Pages
      {
        element: (
          <AuthLoggedin>
            <PageAuth />
          </AuthLoggedin>
        ),
        path: ROUTE_PATHS.LOGIN,
      },
      {
        element: (
          <AuthLoggedin>
            <PageForgotPassword />
          </AuthLoggedin>
        ),
        path: ROUTE_PATHS.FORGOT_PASSWORD,
      },
      {
        element: (
          <AuthLoggedin>
            <PageForgotPassword isResetPassword />
          </AuthLoggedin>
        ),
        path: ROUTE_PATHS.RESET_PASSWORD,
      },
      {
        element: (
          <AuthLoggedin>
            <PageMailSent />
          </AuthLoggedin>
        ),
        path: ROUTE_PATHS.MAIL_SEND,
      },

      // Require Auth Pages
      {
        element: (
          <AuthRequired>
            <PageGoalsGroups isGroupPage={ false } />
          </AuthRequired>
        ),
        path: ROUTE_PATHS.GOALS,
      },
      {
        element: (
          <AuthRequired>
            <PageGoalDetail isEditable={ false } />
          </AuthRequired>
        ),
        path: `${ROUTE_PATHS.GOALS}/:id`, // Route for Goals Detail
      },
      {
        element: (
          <AuthRequired>
            <PageGoalDetail isCreating isEditable />
          </AuthRequired>
        ),
        path: `${ROUTE_PATHS.GOALS}/${CRUD_PATH.CREATE}`, // Route for Goal Create
      },
      {
        element: (
          <AuthRequired>
            <PageGoalDetail isEditable />
          </AuthRequired>
        ),
        path: `${ROUTE_PATHS.GOALS}/:id/${CRUD_PATH.EDIT}`, // Route for Goal Edit
      },
      {
        element: (
          <AuthRequired>
            <PageGoalsGroups isGroupPage />
          </AuthRequired>
        ),
        path: ROUTE_PATHS.GROUPS,
      },
      {
        element: (
          <AuthRequired>
            <PageGroup />
          </AuthRequired>
        ),
        path: `${ROUTE_PATHS.GROUPS}/:id`, // Route Group Detail
      },
      {
        element: (
          <AuthRequired>
            <PageGroupCreate />
          </AuthRequired>
        ),
        path: `${ROUTE_PATHS.GROUPS}/${CRUD_PATH.CREATE}`, // Route Group Create
      },
      {
        element: (
          <AuthRequired>
            <GroupOwnerRequired>
              <PageGroup editMode />
            </GroupOwnerRequired>
          </AuthRequired>
        ),
        path: `${ROUTE_PATHS.GROUPS}/:id/${CRUD_PATH.EDIT}`, // Route Group Edit
      },
      {
        element: (
          <AuthRequired>
            <PageGroupSubscription />
          </AuthRequired>
        ),
        path: `${ROUTE_PATHS.GROUP_SUBSCRIPTION}/:type/:id`, // Route Group Subscription
      },
      {
        element: (
          <AuthRequired>
            <PageUserProfile />
          </AuthRequired>
        ),
        path: ROUTE_PATHS.PROFILE,
      },

      // Question Pages
      {
        Component: PageQuestion,
        children: [
          {
            Component: PageQuestionMapping,
            path: ':id',
          },
        ],
        loader: ({ params }) => {
          if (!params.id || ((params.id as unknown) as number) > 10) {
            return redirect('0');
          }

          return null;
        },
        path: ROUTE_PATHS.QUESTIONS,
      },

      {
        Component: PageActions,
        path: ROUTE_PATHS.ACTIONS,
      },
      {
        Component: PageDashboard,
        path: ROUTE_PATHS.DASHBOARD,
      },
      {
        Component: PageNewPassword,
        path: ROUTE_PATHS.NEW_PASSWORD,
      },
      {
        Component: PageResults,
        path: ROUTE_PATHS.RESULTS,
      },
      {
        Component: PageTop10,
        path: ROUTE_PATHS.TOP_10,
      },
      {
        Component: PageVerifyEmail,
        path: ROUTE_PATHS.VERIFY_EMAIL,
      },
    ],

    id: 'lang',
    loader: ({ params }) => {
      if (!(i18nInstance.languages.includes(params.lang || ''))) {
        return redirect(`/${i18nInstance.language}`);
      }

      return null;
    },
    path: '/:lang',
  },
];

import type { DocumentReference } from 'firebase/firestore';

import { Timestamp, arrayRemove, arrayUnion, updateDoc } from 'firebase/firestore';

import { firebaseAuth } from '~~/firebase';
import { goalRepo, resultRepo, storageRepo, userRepo } from '~~/firebase/repositories';
import { BaseRepository } from '~~/firebase/repositories/repository-factory';

import type { ResultCalculation, ResultData } from '../result/result.entity';
import type { GoalData } from './goal.entity';
import type { GroupData, GroupMember, GroupResultRecordType } from './group.entity';

import { getEarthNumbers, initialResult } from '../result/result.calculation';

const groupNotFoundError = 'Group not found';

export class GroupRepository extends BaseRepository<GroupData> {
  constructor() {
    super('groups');
  }

  calculateGroupResultAverage(results: ResultCalculation[], membersCount: number) {
    const average: ResultCalculation = { ...initialResult };

    if (results.length === 0) {
      return undefined;
    };

    results.forEach((result) => {
      Object.keys(average).forEach((key) => {
        average[key as keyof ResultCalculation] = (average[key as keyof ResultCalculation] + result[key as keyof ResultCalculation]);
      });
    });

    Object.keys(average).forEach((key) => {
      average[key as keyof ResultCalculation] /= membersCount;
    });

    return average;
  }

  async checkIfMemberOfGroup(groupId: string) {
    const userId = firebaseAuth.currentUser?.uid;
    const groupData = await this.getDocument(groupId);
    if (!groupData) {
      throw new Error(groupNotFoundError);
    }

    return groupData.members.some((member) => member.userRef.id === userId);
  }

  async checkIfOwnerOfGroup(groupId: string) {
    const userId = firebaseAuth.currentUser?.uid;
    const groupData = await this.getDocument(groupId);
    if (!groupData) {
      throw new Error(groupNotFoundError);
    }
    return userId === groupData.ownerRef.id;
  }

  async createGroup(groupName: string, image: string, groupType: string) {
    const userId = firebaseAuth.currentUser?.uid;

    const userRef = userRepo.getDocRef(userId);

    const userEarliestResult = await userRepo.getResult(userRef, 'earliest');
    const userLatestResult = await userRepo.getResult(userRef, 'latest');

    const groupRef = this.getDocRef();

    const currentResult: GroupResultRecordType = {
      calculatedResult: '' as unknown as ResultCalculation,
      earthsResult: 0,
      memberResult: [] as unknown as ResultData[],
    };

    const initialResult: GroupResultRecordType = {
      calculatedResult: '' as unknown as ResultCalculation,
      earthsResult: 0,
      memberResult: [] as unknown as ResultData[],
    };

    if (userEarliestResult) {
      initialResult.memberResult.push(resultRepo.getDocRef(userEarliestResult.id) as unknown as ResultData);
      initialResult.earthsResult = getEarthNumbers(userEarliestResult.calculatedResult);
    }
    if (userLatestResult) {
      currentResult.memberResult.push(resultRepo.getDocRef(userLatestResult.id) as unknown as ResultData);
      currentResult.earthsResult = getEarthNumbers(userLatestResult.calculatedResult);
    }

    const imageUrl = image;
    if (!imageUrl.includes('random-images')) {
      const imageRes = await fetch(imageUrl);
      const imageBlob = await imageRes.blob();
      const imageFile = new File([imageBlob], `${groupName}.jpg`, { type: 'image/jpeg' });
      await storageRepo.uploadImage(imageFile);
    }

    await this.setDocument({
      data: {
        currentResult,
        description: '',
        groupName,
        groupType,
        image: imageUrl,
        initialResult,
        location: '',
        members: [
          {
            joinDate: Timestamp.now(),
            roles: 'owner',
            userRef,
          },
        ],
        ownerRef: userRef,
        pendingRequest: [],
      },
      id: groupRef.id,
    });

    await userRepo.appendGroups(groupRef);

    return {
      groupName,
      id: groupRef.id,
    };
  }

  async getCalculatedGroupResult(currentResultRefs: DocumentReference[], initialResultRefs: DocumentReference[]) {
    const currentGroupResults = await Promise.all(currentResultRefs.map(async (resultRef) => await resultRepo.getDocument(resultRef.id)));

    const initialGroupResults = await Promise.all(initialResultRefs.map(async (resultRef) => await resultRepo.getDocument(resultRef.id)));

    const calculatedCurrentGroupResult = this.calculateGroupResultAverage(currentGroupResults.filter((result) => result).map((filteredResult) => filteredResult!.calculatedResult), currentGroupResults.length);

    const calculatedInitialGroupResult = this.calculateGroupResultAverage(initialGroupResults.filter((result) => result).map((filteredResult) => filteredResult!.calculatedResult), initialGroupResults.length);

    return {
      calculatedCurrentGroupResult,
      calculatedInitialGroupResult,
    };
  }

  async getGroupDetail(groupdId: string) {
    const groupData = await this.getDocument(groupdId);
    if (!groupData) {
      throw new Error(groupNotFoundError);
    };
    const memberRefs = groupData.members;

    const goalData = await goalRepo.getDocument(groupData.goals?.id || '');

    if (goalData) {
      groupData.goals = goalData;
    }

    const members = await Promise.all(memberRefs.map(async (member) => {
      const userData = await userRepo.getDocument(member.userRef.id);
      if (userData) {
        return {
          joinDate: member.joinDate,
          roles: member.roles,
          userData,
          userRef: member.userRef,
        };
      }
      return null;
    }));

    const { calculatedCurrentGroupResult, calculatedInitialGroupResult } = await this.getCalculatedGroupResult(groupData.currentResult.memberResult as unknown as DocumentReference[], groupData.initialResult.memberResult as unknown as DocumentReference[]);

    groupData.currentResult.calculatedResult = calculatedCurrentGroupResult;

    groupData.initialResult.calculatedResult = calculatedInitialGroupResult;
    groupData.id = groupdId;

    groupData.members = members.filter((member) => member !== null) as GroupMember[];

    if (calculatedCurrentGroupResult) {
      groupData.currentResult.earthsResult = getEarthNumbers(calculatedCurrentGroupResult);
    }

    return groupData;
  }

  async joinGroup(groupId: string) {
    const userId = firebaseAuth.currentUser?.uid || '';
    const groupRef = this.getDocRef(groupId);
    const userRef = userRepo.getDocRef(userId);
    const groupData = await this.getDocument(groupId);

    if (!groupData) {
      throw new Error(groupNotFoundError);
    }

    const isUserExist = groupData.members.some((member) => member.userRef.id === userRef.id);
    if (isUserExist) {
      return;
    }

    const roles = groupData.ownerRef.id === userRef.id ? 'owner' : 'member';
    const joinData = {
      joinDate: Timestamp.now(),
      roles,
      userRef,
    };

    const userLatestResult = await userRepo.getResult(userRef, 'latest');
    const userEarliestResult = await userRepo.getResult(userRef, 'earliest');

    if (userLatestResult) {
      const resultRef = resultRepo.getDocRef(userLatestResult.id);
      groupData.currentResult.memberResult.push(resultRef as unknown as ResultData);
    }
    if (userEarliestResult) {
      const resultRef = resultRepo.getDocRef(userEarliestResult.id);
      groupData.initialResult.memberResult.push(resultRef as unknown as ResultData);
    }

    await updateDoc(groupRef, {
      currentResult: groupData.currentResult,
      initialResult: groupData.initialResult,
      members: arrayUnion(joinData),
    });
    await userRepo.appendGroups(groupRef);
  }

  async removeMember(memberId: string, groupData: GroupData) {
    const userRef = userRepo.getDocRef(memberId);
    const groupRef = this.getDocRef(groupData.id);

    const currentResults = await Promise.all(groupData.currentResult.memberResult.map(async (resultRef) => await resultRepo.getDocument((resultRef as unknown as DocumentReference).id)));

    const initialResults = await Promise.all(groupData.initialResult.memberResult.map(async (resultRef) => await resultRepo.getDocument((resultRef as unknown as DocumentReference).id)));

    const dataForNewCurrentGroupResult = currentResults.filter((result) => {
      return result && result.userRef.id !== userRef.id;
    }).map((filteredResult) => resultRepo.getDocRef(filteredResult?.id));

    const dataForInitialGroupResult = initialResults.filter((result) => {
      return result && result.userRef.id !== userRef.id;
    }).map((filteredResult) => resultRepo.getDocRef(filteredResult?.id));

    const removedMembers = groupData.members.filter((member) => member.userRef.id !== userRef.id);

    await updateDoc(userRef, {
      groups: arrayRemove(groupRef),
    });

    const { calculatedCurrentGroupResult, calculatedInitialGroupResult } = await this.getCalculatedGroupResult(dataForNewCurrentGroupResult, dataForNewCurrentGroupResult);

    await updateDoc(groupRef, {
      currentResult: {
        calculatedResult: calculatedCurrentGroupResult,
        earthsResult: calculatedCurrentGroupResult ? getEarthNumbers(calculatedCurrentGroupResult) : 0,
        memberResult: dataForNewCurrentGroupResult,
      },
      initialResult: {
        calculatedResult: calculatedInitialGroupResult,
        earthsResult: calculatedInitialGroupResult ? getEarthNumbers(calculatedInitialGroupResult) : 0,
        memberResult: dataForInitialGroupResult,
      },
      members: removedMembers.map((member) => ({
        joinDate: member.joinDate,
        roles: member.roles,
        userRef: member.userRef,
      })),
    });

    return {
      calculatedCurrentGroupResult,
      calculatedInitialGroupResult,
      members: removedMembers,
    };
  }

  async updateGroup(groupData: GroupData, groupId: string) {
    const goal = groupData.goals;
    const groupRef = this.getDocRef(groupId);
    const updateData: Pick<GroupData, 'description' | 'goals' | 'groupName' | 'image' | 'location'> = {
      description: groupData.description,
      groupName: groupData.groupName,
      image: groupData.image,
      location: groupData.location,
    };
    if (goal) {
      updateData.goals = goalRepo.getDocRef(goal.id) as unknown as GoalData;
    }

    await updateDoc(groupRef, updateData);
  }

  async updateGroupResult(newResult: ResultData) {
    const userId = firebaseAuth.currentUser!.uid;
    const userData = await userRepo.getDocument(userId);
    if (!userData) {
      throw new Error('User not found');
    };
    const groupRefs = userData.groups;

    groupRefs.forEach(async (ref) => {
      const groupData = await this.getDocument(ref.id);
      if (groupData) {
        const newResults: Array<DocumentReference> = [];

        const oldResults = await Promise.all(groupData.currentResult.memberResult.map(async (resultRef) => await resultRepo.getDocument((resultRef as unknown as DocumentReference).id)));

        for (const result of oldResults) {
          if (result) {
            // Replace old user result with new user result
            if (result.userRef.id === userId) {
              newResults.push(resultRepo.getDocRef(newResult.id));
            } else {
              // keep other user results
              newResults.push(resultRepo.getDocRef(result.id));
            }
          }
        }

        await updateDoc(ref, {
          currentResult: {
            memberResult: newResults,
          },
        });
      }
    });
  }
}


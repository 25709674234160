import { isBoolean } from '@vinicunca/perkakas';
import { useTranslation } from 'react-i18next';

import { COOKIES_LOCAL_STORAGE } from '~~/app/core/entities/storage.entity';
import { useLocalStorage } from '~~/app/core/hooks';
import { MhiButton } from '~~/design-system/components/mhi-button';
import { MhiDialog, MhiDialogContent, MhiDialogFooter, MhiDialogTitle } from '~~/design-system/components/mhi-dialog';
import { MhiParagraph } from '~~/design-system/components/mhi-paragraph';

export function CoreDialogCookie() {
  const [cookieLocal, setCookieLocal] = useLocalStorage<boolean | undefined>(COOKIES_LOCAL_STORAGE, undefined);

  const { t } = useTranslation('dialog');

  return (
    <MhiDialog open={ !isBoolean(cookieLocal) }>
      <MhiDialogContent isBlack>
        <MhiDialogTitle>
          { t('cookies.title') }
        </MhiDialogTitle>

        <MhiParagraph className="mt-2 mhi:(py-0 text-size-sm-5 leading-sm) color-primary">
          { t('cookies.content') }
        </MhiParagraph>

        <img alt="cookie" className="mt-2 sm:mt-4 max-w-[149px]" src="/images/img-cookies.svg" />

        <MhiDialogFooter>
          <MhiButton
            onClick={ () => setCookieLocal(true) }
            variant="outline"
          >
            { t('cookies.positive-button') }
          </MhiButton>

          <MhiButton
            className="ml-5"
            onClick={ () => setCookieLocal(false) }
            variant="header"
          >
            { t('cookies.negative-button') }
          </MhiButton>
        </MhiDialogFooter>
      </MhiDialogContent>
    </MhiDialog>
  );
}

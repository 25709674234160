import { FirebaseError } from 'firebase/app';
import { Timestamp, deleteDoc, getDocs, query, where } from 'firebase/firestore';

import { BaseRepository } from '~~/firebase/repositories/repository-factory';

interface NewsletterDoc {
  createdAt: Timestamp;
  email: string;
}

export class NewsletterRepository extends BaseRepository<NewsletterDoc> {
  constructor() {
    super('newsletter');
  };

  async checkSubscription(email: string) {
    const nlSnapshot = await getDocs(query(this.collectionRef, where('email', '==', email)));

    if (!nlSnapshot.empty) {
      throw new FirebaseError('firestore/newsletter-exist', 'Email already subscribe a newsletter');
    }
  }

  async subscribe(email: string) {
    const nlRef = this.getDocRef();

    await this.checkSubscription(email);

    await this.setDocument({
      data: {
        createdAt: Timestamp.now(),
        email,
      },
      id: nlRef.id,
    });

    return nlRef;
  }

  async unsubscribe(id: string) {
    const nlRef = this.getDocRef(id);

    await deleteDoc(nlRef);
  }
}

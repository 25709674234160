import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import classNames from 'classnames';
import * as React from 'react';

import { LocalizedLink } from '~~/i18n/localized-link';

export const buttonVariants = cva(
  'inline-block transition-colors-280 inline-flex items-center outline-none',
  {
    defaultVariants: {
      size: 'medium',
      variant: 'primary',
    },
    variants: {
      size: {
        medium: 'px-[18px] py-1.5 rounded-full',
        small: 'py-0 px-5 h-8 mr-[10px] rounded-4',
      },
      variant: {
        header: 'mhi:(rounded-1 py-2 px-4) text-size-sm-4-1 hover:(bg-white/50) color-primary',
        info: 'w-5.5 h-5.5 mhi:(p-0 pt-1) flex items-center justify-center border border-primary rounded-1/2 text-size-sm-6 color-primary font-heading font-bold hover:scale-110 transition-all-250',
        outline: 'color-primary rounded-4 border border-primary hover:(scale-110) text-size-sm-4 mhi:transition-all-250',
        primary: 'bg-primary border-1 border-primary mhi:transition-all-250 text-size-sm-4 color-white hover:scale-110',
        secondary: 'bg-secondary color-white font-heading font-700 text-size-md-3 leading-[11px] mhi:transition-transform-280 hover:scale-110 max-h-9 sm:(max-h-11.5 leading-sm text-size-md-5)',
      },
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
  label?: string;
  to?: string;
}

export const MhiButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({
    asChild = false,
    children,
    className,
    disabled,
    isLoading = false,
    label,
    size,
    to,
    variant,
    ...props
  }, ref) => {
    const Comp = asChild || to ? Slot : 'button';
    const rootClasses = classNames(
      className,
      {
        'bg-disabled': !!disabled && variant === 'secondary',
        'mhi:(bg-core)': !!disabled && variant === 'outline',
        'mhi:(border-[1px] border-solid border-disabled)': !!disabled && variant === 'outline',
        'mhi:(color-disabled pointer-events-none)': !!disabled,
        'mhi:(pointer-events-none)': isLoading,
      },
      buttonVariants({ size, variant }),
    );

    return (
      <Comp
        className={ rootClasses }
        ref={ ref }
        { ...props }
      >
        { to
          ? (
            <LocalizedLink to={ to }>
              { children }
            </LocalizedLink>
            )
          : (
            <>
              {
                isLoading
                  ? <i className="i-mhi-loading" />
                  : children
              }
            </>
            ) }
      </Comp>
    );
  },
);

MhiButton.displayName = 'MhiButton';


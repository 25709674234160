import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import '@unocss/reset/tailwind.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import 'uno.css';

import App from '~~/App.tsx';

import { LayoutLoading } from './app/layouts/components/layout-loading';
import './designs/styles/index.css';
import { i18nInstance } from './i18n';

// eslint-disable-next-line node/prefer-global/process
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: import.meta.env.VITE_PUBLIC_SENTRY_DSN,
    integrations: [],
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <I18nextProvider i18n={ i18nInstance }>
      <Suspense fallback={ <LayoutLoading /> }>
        <QueryClientProvider client={ queryClient }>
          <App />

          <ReactQueryDevtools initialIsOpen={ false } />
        </QueryClientProvider>
      </Suspense>
    </I18nextProvider>
  </React.StrictMode>,
);

import type { DocumentReference, Timestamp } from 'firebase/firestore';

import type { QuestionDataUser } from '~~/app/question/question.entity';
import type { UserDoc } from '~~/app/user/user.entity';

import { QUESTION } from '~~/app/question/question.entity';

export interface ResultCalculation {
  [QUESTION.BATHING]: number;
  [QUESTION.CAR]: number;
  [QUESTION.CLOTHES]: number;
  [QUESTION.DAIRY]: number;
  [QUESTION.FLYING]: number;
  [QUESTION.FOOD]: number;
  [QUESTION.HOUSING]: number;
  [QUESTION.MEAT]: number;
  [QUESTION.POSSESSIONS]: number;
  [QUESTION.PUBLIC_TRANSPORT]: number;
}

export type ResultCalculationKey = keyof ResultCalculation;

export interface ResultData {
  [QUESTION.USER]: QuestionDataUser;
  calculatedResult: ResultCalculation;
  createdAt: Timestamp;
  id: string;
  stringResult: string;
  userRef: DocumentReference<UserDoc>;
}

export const RESULT_BENCHMARK_SCORE: Record<string, number> = {
  [QUESTION.BATHING]: 25.78,
  [QUESTION.CAR]: 174.5,
  [QUESTION.CLOTHES]: 38.94,
  [QUESTION.DAIRY]: 30.81,
  [QUESTION.FLYING]: 66.95,
  [QUESTION.FOOD]: 46.72,
  [QUESTION.HOUSING]: 138.42,
  [QUESTION.MEAT]: 106.67,
  [QUESTION.POSSESSIONS]: 266.08,
  [QUESTION.PUBLIC_TRANSPORT]: 10.07,
};

import type { StorageReference } from 'firebase/storage';

import { sample } from '@vinicunca/perkakas';
import { FirebaseError } from 'firebase/app';
import { Timestamp } from 'firebase/firestore';
import { deleteObject, getDownloadURL, getStorage, listAll, ref, uploadBytes } from 'firebase/storage';

import { firebaseAuth } from '..';
export class StorageRepository {
  prevSelectedImage: StorageReference | null = null;
  randomImages: StorageReference[] | null = null;
  storage = getStorage();

  checkRandomImage(url: string) {
    const imageRef = ref(this.storage, url);
    return imageRef.parent?.name === 'random-images';
  }

  async deleteImage(url: string) {
    const imageRef = ref(this.storage, url);
    await deleteObject(imageRef);
  }

  async getRandomImage(): Promise<string> {
    const listRef = ref(this.storage, 'random-images');
    if (!this.randomImages) {
      const imageListResults = await listAll(listRef);
      this.randomImages = imageListResults.items;
    }
    let attempts = 0;
    let image = sample(this.randomImages, 1)[0];
    if (this.randomImages.length > 1) {
      while (this.prevSelectedImage === image && attempts < this.randomImages.length) {
        image = sample(this.randomImages, 1)[0];
        attempts++;
      }
    }

    this.prevSelectedImage = image as StorageReference;

    if (!image) {
      throw new FirebaseError('storage/image-not-exist', 'Failed to get profile picture');
    }
    return await getDownloadURL(image);
  }

  async uploadImage(file: File): Promise<null | string> {
    if (!file.type.startsWith('image')) {
      throw new FirebaseError('storage/invalid-type', 'Invalid file type, you should upload image file');
    };
    if (file.size > 5242880) {
      throw new FirebaseError('storage/size-limit', 'File should be less than 5 MB');
    }
    const fileUniqueId = `${Timestamp.now().seconds}_${firebaseAuth.currentUser!.uid}`;
    const storageRef = ref(this.storage, `public/${fileUniqueId}_${file.name}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  }
}

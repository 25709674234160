import type { VariantProps } from 'class-variance-authority';

import { cva } from 'class-variance-authority';
import classNames from 'classnames';

import type { CompProps } from '~~/typings/props';

const headingVariants = cva(
  'flex flex-wrap items-center justify-center text-center font-heading font-600 all-[span]:px-[2px]',
  {
    variants: {
      type: {
        h1: 'text-size-md-4 sm:text-size-md-8',
        h2: 'text-size-md-4 sm:text-size-md-6',
        h3: 'text-size-md-4',
        h4: 'text-size-md-2',
      },
    },
  },
);

interface MhiHeadingProps extends VariantProps<typeof headingVariants> {
  Tag: `h${1 | 2 | 3 | 4}`;
}

export function MhiHeading({ Tag, children, className }: CompProps<MhiHeadingProps>) {
  const rootClasses = classNames(
    className,
    headingVariants({ type: Tag }),
  );

  return (
    <Tag className={ rootClasses }>
      { children }
    </Tag>
  );
}

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMatches } from 'react-router-dom';

import { useUserComputed, useUserStore } from '~~/app/user/user.store';
import { MhiButton } from '~~/design-system/components/mhi-button';
import { useLocalizedRouter } from '~~/i18n/hooks/use-localized-router';
import { ROUTE_PATHS } from '~~/router';

export function LayoutHeaderLinks({ isOpen, setOpen }: { isOpen: boolean; setOpen: (value: boolean) => void }) {
  const { t } = useTranslation('common');
  const matches = useMatches();
  const { navigate } = useLocalizedRouter();
  const { isLoggedIn } = useUserComputed();
  const user = useUserStore.use.user();
  const hasResult = (user?.results.length || 0) > 0;

  function isActive(to: string) {
    const firstMatch = matches[1];

    if (!firstMatch) {
      return false;
    }

    return {
      'bg-core': firstMatch.pathname.includes(`/${to}`),
    };
  }

  function getMenuItems() {
    const menuItems = [
      {
        disabled: false,
        label: t('header.dashboard'),
        to: ROUTE_PATHS.DASHBOARD,
      },
    ];

    if (isLoggedIn) {
      menuItems.push(
        {
          disabled: !hasResult,
          label: t('header.actions'),
          to: ROUTE_PATHS.ACTIONS,
        },
        {
          disabled: !hasResult,
          label: t('header.results'),
          to: ROUTE_PATHS.RESULTS,
        },
        {
          disabled: !hasResult,
          label: t('header.goals'),
          to: ROUTE_PATHS.GOALS,
        },
        {
          disabled: !hasResult,
          label: t('header.groups'),
          to: ROUTE_PATHS.GROUPS,
        },
      );
    }

    return menuItems;
  }

  return (
    <div className={ classNames('fixed flex flex-col-reverse gap-3 items-center justify-end bottom-[calc(100vh-60px)] p-5 left-0 w-full h-[calc(100vh-60px)] z-background transition-all-300 bg-white-darker md:(relative h-full top-0 bg-transparent w-fit p-0 flex-row z-base)', {
      'translate-y-full md:translate-0': isOpen,
    }) }
    >

      <div className="flex flex-col gap-3 w-full md:(flex-row w-fit)">
        { getMenuItems().map((menuItem) => (
          <MhiButton
            className={ classNames(
              'bg-core w-full h-16 flex items-center justify-center md:(bg-transparent h-fit w-fit mhi:(hover:bg-core/40))',
              isActive(menuItem.to),
            ) }
            disabled={ menuItem.disabled }
            key={ menuItem.to }
            onClick={ () => {
              navigate(menuItem.to);
              setOpen(false);
            } }
            variant="header"
          >
            { menuItem.label }
          </MhiButton>
        )) }
      </div>

      {
        isLoggedIn
          ? (
            <MhiButton to={ ROUTE_PATHS.PROFILE } variant="header">
              <img
                alt="profile picture"
                className="w-15 h-15 md:(w-[50px] h-[50px]) rounded-full object-cover"
                onClick={ () => setOpen(false) }
                src={ user?.photoUrl }
              />
            </MhiButton>
            )
          : (
            <MhiButton
              className="ml-4 mhi:(border-none md:border-solid)"
              onClick={ () => {
                navigate(ROUTE_PATHS.LOGIN);
                setOpen(false);
              } }
              variant="outline"
            >
              <i className="i-mhi-login h-15 w-15 md:(h-[18px] w-[18px]) transition-colors-280" />

              <span className="hidden md:block ml-2 text-size-sm-4-1">
                { t('header.login') }
              </span>
            </MhiButton>
            )
      }
    </div>
  );
}

import { useSuspenseQuery } from '@tanstack/react-query';

import { useResultStore } from '~~/app/result/result.store';
import { useUserStore } from '~~/app/user/user.store';
import { firebaseAuth } from '~~/firebase';
import { userRepo } from '~~/firebase/repositories';

export function useCheckUser() {
  const setUser = useUserStore.use.setUser();
  const setResults = useResultStore.use.setResults();

  return useSuspenseQuery({
    notifyOnChangeProps: ['data'],
    queryFn: async () => {
      await firebaseAuth.authStateReady();

      if (firebaseAuth.currentUser) {
        const user = await userRepo.fetchCurrentUser(firebaseAuth.currentUser.uid);

        setUser(user);

        if (user?.results[0]) {
          setResults(user.results[0]);
        }
      }

      return null;
    },

    queryKey: ['checkAuth'],
  });
}

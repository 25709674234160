import classNames from 'classnames';
import HTMLReactParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import type { CompProps } from '~~/typings/props';

interface MhiParagraphProps extends CompProps {
  i18nKey?: string;
}

export function MhiParagraph({
  children,
  className,
  i18nKey,
}: MhiParagraphProps) {
  const { t } = useTranslation(['paragraph']);
  const Comp = i18nKey ? 'div' : 'span';

  const rootClasses = classNames(
    'relative text-center p-5 color-dark mx-auto max-w-[773px] text-size-sm-5 leading-sm-2-1 sm:(text-size-base leading-sm-3)',
    className,
  );

  return (
    <Comp className={ rootClasses }>
      { i18nKey ? HTMLReactParser(t(i18nKey)) : children }
    </Comp>
  );
}

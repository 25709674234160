import * as DialogPrimitive from '@radix-ui/react-dialog';
import classNames from 'classnames';
import React from 'react';

import { MhiHeading } from './mhi-heading';

export const MhiDialog = DialogPrimitive.Root;

export const MhiDialogTrigger = DialogPrimitive.Trigger;

function MhiDialogPortal({
  ...props
}: DialogPrimitive.DialogPortalProps) {
  return (
    <DialogPrimitive.Portal
      { ...props }
    />
  );
};
MhiDialogPortal.displayName = DialogPrimitive.Portal.displayName;

interface MhiDialogOverlayProps extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> {
  isBlack?: boolean;
}

const MhiDialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  MhiDialogOverlayProps
>(({ className, isBlack, ...props }, ref) => (
  <DialogPrimitive.Overlay
    className={ classNames(
      'fixed inset-0 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className,
      {
        'bg-black/40 z-dialog': isBlack,
        'bg-primary/90 z-dialog': !isBlack,
      },
    ) }
    ref={ ref }
    { ...props }
  />
));
MhiDialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

interface MhiDialogContentProps extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> {
  isBlack?: boolean;
}

export const MhiDialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  MhiDialogContentProps
>(({ children, className, isBlack, ...props }, ref) => (
  <MhiDialogPortal>
    <MhiDialogOverlay isBlack={ isBlack } />

    <DialogPrimitive.Content
      className={ classNames(
        'fixed left-1/2 top-1/2 -translate-1/2 bg-white rounded-3 overflow-hidden z-dialog md:w-[574px] w-[calc(100%-40px)] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
        className,
      ) }
      ref={ ref }
      { ...props }
    >
      <div className="flex flex-col items-center w-full h-full">
        { children }
      </div>
    </DialogPrimitive.Content>
  </MhiDialogPortal>
));
MhiDialogContent.displayName = DialogPrimitive.Content.displayName;

export const MhiDialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ children, className, ...props }, ref) => (
  <DialogPrimitive.Title
    asChild
    ref={ ref }
    { ...props }
  >
    <MhiHeading
      Tag="h2"
      className={ classNames(
        'relative color-secondary pt-9 px-13 sm:(pt-7 px-17 mhi:text-size-md-8) mhi:(inline-block text-size-md-4) after:(color-tertiary i-mhi-sun content-empty absolute w-[38px] h-[38px] top-[11px] left-[20px]) sm:after:top-[11px]',
        className,
      ) }
    >
      { children }
    </MhiHeading>
  </DialogPrimitive.Title>
));
MhiDialogTitle.displayName = DialogPrimitive.Title.displayName;

export function MhiDialogFooter({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={ classNames(
        'bg-primary-lighterest mt-7 py-8 md:(mt-0 pb-8) flex justify-center w-full',
        className,
      ) }
      { ...props }
    />
  );
};
MhiDialogFooter.displayName = 'DialogFooter';

import { useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { loadTranslations } from '~~/i18n/locales/loader';

/**
 * When initializing the app, we need to fetch the translations from Contentful.
 * The result will be added into the i18n-next resources.
 */
export function useFetchTranslationsContent() {
  const { i18n } = useTranslation();

  useSuspenseQuery({
    queryFn: loadResources,

    queryKey: ['contentful'],
  });

  async function loadResources() {
    const translations = await loadTranslations(i18n.language);

    Object.keys(translations).forEach((namespace) => {
      i18n.addResourceBundle(i18n.language, namespace, translations[namespace]);
    });
    // If we are in development mode, we use the local translations in /i18n/locales
    // if (import.meta.env.DEV) {
    // } else {
    //   const data = await API.get('MhiContent', `/contentful?locale=${i18n.language}`, {});

    //   i18n.addResourceBundle(i18n.language, 'translation', data);
    // }

    return null;
  }
}

import { createBrowserRouter } from 'react-router-dom';

import { triggerPageView } from '~~/app/analytics';

import { routes } from './routes';

export const router = createBrowserRouter(routes);

// inspired by https://stackoverflow.com/questions/70646421/how-to-listen-for-route-change-in-react-router-dom-v6
router.subscribe((state) => {
  triggerPageView({ historyAction: state.historyAction, pathname: location.pathname });
});

export { ROUTE_PATHS } from './entity.router';

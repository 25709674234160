import { useFunctionsCall } from '~~/firebase/firebase.functions';

const NAMESPACES = [
  'dialog',
  'label',
  'slider',
  'selection',
  'paragraph',
  'action',
  'actionTip',
  'actionButton',
];

export async function loadTranslations(locale: string) {
  const namespaces: any = {};
  let i18nResult: any;

  const mutationContentfulCache = useFunctionsCall<{
    lang: string;
  }>('contentfulCache', {
    mutationOptions: {
      onSuccess: async (data) => {
        i18nResult = data!;
      },
    },
  });

  await mutationContentfulCache.mutateAsync({ lang: locale });

  NAMESPACES.forEach((namespace) => {
    namespaces[namespace] = i18nResult.data[`${namespace}-${locale}`];
  });

  return namespaces;
}


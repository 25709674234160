import type { FirebaseOptions } from 'firebase/app';

import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

const { VITE_FIREBASE_CONFIG } = import.meta.env;

const firebaseConfig = JSON.parse(VITE_FIREBASE_CONFIG) as FirebaseOptions;

const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firebaseApp);

export const firebaseFunctions = getFunctions(firebaseApp, 'europe-west1');

export const firestore = getFirestore(firebaseApp);

export const storage = getStorage(firebaseApp);

if (import.meta.env.DEV) {
  const host = '127.0.0.1';
  const {
    auth: authEmulator,
    firestore: firestoreEmulator,
    functions: functionsEmulator,
    storage: storageEmulator,
  } = __FIREBASE_EMULATOR_CONFIGS__;

  connectAuthEmulator(
    firebaseAuth,
    `http://${host}:${authEmulator.port}`,
  );

  connectFunctionsEmulator(firebaseFunctions, host, functionsEmulator.port);
  connectFirestoreEmulator(firestore, host, firestoreEmulator.port);
  connectStorageEmulator(storage, host, storageEmulator.port);
}

import type { NavigateOptions } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function useLocalizedRouter() {
  const { i18n } = useTranslation();
  const navigate_ = useNavigate();

  function navigate(to: string, option?: NavigateOptions) {
    return navigate_(`/${i18n.language}/${to}`, option);
  };

  return {
    navigate,
  };
}

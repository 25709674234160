import { RESULT_BENCHMARK_SCORE, type ResultCalculation } from './result.entity';

const TREES_GEM_NL = 2170;
const HA_GEM_NL = 4.3;
const TOTAL_RECIPE_POINTS_TOP10 = 906;

export const initialResult: ResultCalculation = {
  BATHING: 0,
  CAR: 0,
  CLOTHES: 0,
  DAIRY: 0,
  FLYING: 0,
  FOOD: 0,
  HOUSING: 0,
  MEAT: 0,
  POSSESSIONS: 0,
  PUBLIC_TRANSPORT: 0,
};

export function getEarthNumbers(results: ResultCalculation) {
  const totalBenchmarkScore = Object.values(RESULT_BENCHMARK_SCORE).reduce((a, b) => a + b, 0);

  const totalResultsScore = Object.values(results).reduce((a, b) => a + b, 0);

  const scorePerEarths = totalBenchmarkScore / 3;
  const numberOfEarths = Math.round(10 * totalResultsScore / scorePerEarths) / 10 || 0;
  let roundedEarths = Math.round(numberOfEarths * 10) / 10;

  if (roundedEarths > 10) {
    roundedEarths = Math.round(roundedEarths);
  }

  return Number.parseFloat(numberOfEarths.toFixed(1));
}

export function getTreeNumbers(results: ResultCalculation) {
  const totalResultsScore = Object.values(results).reduce((a, b) => a + b, 0);

  const recipeFactor = totalResultsScore / TOTAL_RECIPE_POINTS_TOP10;
  const trees = Math.floor(recipeFactor * TREES_GEM_NL);
  const ha = Math.round((recipeFactor * HA_GEM_NL) * 10) / 10;

  return {
    ha,
    trees,
  };
}

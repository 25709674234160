import type { LinkProps } from 'react-router-dom';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ROUTE_PATHS } from '~~/router';

export const LocalizedLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({
    children,
    className,
    to,
  }, ref) => {
    const { i18n } = useTranslation();

    let path = `/${i18n.language}/`;

    if (to !== ROUTE_PATHS.ROOT) {
      path += to;
    }

    return (
      <Link
        className={ className }
        ref={ ref }
        to={ path }
      >
        { children }
      </Link>
    );
  },
);

LocalizedLink.displayName = 'LocalizedLink';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { CompProps } from '~~/typings/props';

import { MhiHeading } from '~~/design-system/components/mhi-heading';

const socialLinks = [
  {
    icon: 'i-mhi-social-mail',
    label: 'mail',
    link: 'mailto:info@thinkbigactnow.org',
  },
  {
    icon: 'i-mhi-social-linkedin',
    label: 'linkedin',
    link: 'https://www.linkedin.com/company/think-big-act-now',
  },
  {
    icon: 'i-mhi-social-instagram',
    label: 'instagram',
    link: 'https://www.instagram.com/thinkbigactnow/',
  },
  {
    icon: 'i-mhi-social-facebook',
    label: 'facebook',
    link: 'https://www.facebook.com/thinkbigactnow/',
  },
  {
    icon: 'i-mhi-social-twitter',
    label: 'twitter',
    link: 'https://twitter.com/thinkbig_actnow',
  },
];

export function LayoutFooter({ className }: CompProps) {
  const { t } = useTranslation('label');
  const footerClasses = classNames(
    'relative bg-primary text-center pt-3 z-footer color-white',
    className,
  );

  return (
    <footer className={ footerClasses }>
      <div className="absolute w-full bg-center top-[-52px] h-[64px] bg-[length:auto_100%] bg-[url(/backgrounds/bg-water.svg)]" />

      <div className="mx-5">
        <figcaption className="sm:mt-7">
          <img
            alt="Logo"
            className="inline-block h-[95px] w-[110px] sm:(h-[140px] w-[162px])"
            src="/images/img-mijn-verborgen-impact-logo.svg"
          />
        </figcaption>

        <MhiHeading
          Tag="h3"
          className="mt-6 sm:mt-8 mhi:text-size-md-3 "
        >
          { t('footer-contact') }
        </MhiHeading>

        <ul className="max-w-[225px] flex justify-center flex-wrap mx-auto sm:max-w-full">
          { socialLinks.map((item) => (
            <li
              className="relative w-[43px] h-[43px] mt-2 mx-4 mb-3 sm:(my-1)"
              key={ item.label }
            >
              <Link
                className="bg-white rounded-full absolute h-full w-full flex items-center justify-center color-primary text-size-sm-6"
                target="_blank"
                to={ item.link }
              >
                <i className={ item.icon } />
              </Link>
            </li>
          )) }
        </ul>

        <p className="text-size-sm-4 opacity-70 pt-5 pb-[56px] sm:(pt-10.5 pb-22)">
          { t('footer-copyright') }
        </p>
      </div>
    </footer>
  );
}

import { COOKIES_LOCAL_STORAGE } from '~~/app/core/entities/storage.entity';

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
      // [::1] is the IPv6 localhost address.
      || window.location.hostname === '[::1]'
      // 127.0.0.0/8 are considered localhost for IPv4.
      || window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
      ),
);

export const isTestEnvironment = Boolean(
  window.location.hostname.includes('footprintcalculator-test'),
);

export function cookiesAccepted() {
  return window.localStorage.getItem(COOKIES_LOCAL_STORAGE) === 'true';
}

export function analyticsAllowedToTrigger() {
  return cookiesAccepted() && !isLocalhost && !isTestEnvironment;
}

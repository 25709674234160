// i18n specific
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { enLocales } from './locales/en';
import { nlLocales } from './locales/nl';

// eslint-disable-next-line ts/no-floating-promises
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: import.meta.env.DEV, // enable on DEV environment
    detection: {
      order: ['path', 'localStorage', 'navigator'],
    },
    fallbackLng: () => {
      const { host } = window.location;

      if (host.includes('mijnverborgenimpact.nl')) {
        return 'nl';
      } else {
        return 'en';
      }
    },
    resources: {
      en: enLocales,
      nl: nlLocales,
    },
    supportedLngs: ['en', 'nl'],
  });

i18n.on('languageChanged', (lng) => { // inspired by https://stackoverflow.com/questions/71210108/how-to-change-replace-windows-location-locale-after-changing-language-using-i18
  const { location } = window;
  const splittedPath = location.pathname.split('/');
  const enRegex = /\/en.*/;
  const nlRegex = /\/nl.*/;
  if ((enRegex.test(location.pathname) || nlRegex.test(location.pathname)) && splittedPath[1] !== lng) {
    splittedPath[1] = lng;
    const newUrl = splittedPath.join('/');
    location.replace(newUrl);
  }
});

export { i18n as i18nInstance };

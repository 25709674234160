import type { NavigateProps } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { ROUTE_PATHS } from '.';

export function LocalizedNavigate({ relative, replace, state, to }: NavigateProps) {
  const { i18n } = useTranslation();
  return (
    <Navigate
      relative={ relative }
      replace={ replace }
      state={ state }
      to={ `/${i18n.language}${to.toString() === ROUTE_PATHS.ROOT ? '' : '/'}${to.toString()}` }
    />
  );
}

import classNames from 'classnames';
import { Outlet, useLocation } from 'react-router-dom';

import { useUserStore } from '~~/app/user/user.store';
import { ROUTE_PATHS } from '~~/router';
import { LocalizedNavigate } from '~~/router/localizedNavigate';

import { LayoutFooter } from './layout-footer';
import { LayoutHeader } from './layout-header';
import { ScrollBehavior } from './scroll-behavior';

export function LayoutBase() {
  const location = useLocation();
  const user = useUserStore.use.user();

  const isQuestionPage = location.pathname.includes('question');

  if (((user?.results.length || 0) < 1) && ['goals', 'actions', 'groups'].some((path) => location.pathname.includes(path))) {
    return <LocalizedNavigate replace to={ ROUTE_PATHS.ROOT } />;
  }

  return (
    <div className={
      classNames(
        'h-full',
        {
          'pt-15 md:pt-18': !isQuestionPage,
        },
      )
      }
    >
      <ScrollBehavior />

      <LayoutHeader
        className={
          classNames({
            hidden: isQuestionPage,
          })
        }
      />

      <Outlet />

      <LayoutFooter
        className={
          classNames({
            hidden: isQuestionPage,
          })
        }
      />
    </div>
  );
}

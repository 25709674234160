import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollBehavior() {
  const location = useLocation();
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const getScrollPosition = localStorage.getItem('scrollPosition');

    if (getScrollPosition && firstRender) {
      window.scrollTo(0, Number.parseFloat(getScrollPosition));
      setFirstRender(false);
      setTimeout(() => {
        localStorage.removeItem('scrollPosition');
      }, 100);
    }

    function setScrollPosition() {
      localStorage.setItem('scrollPosition', Math.round(window.scrollY).toString());
    }

    window.addEventListener('beforeunload', setScrollPosition);

    return () => window.removeEventListener('beforeunload', setScrollPosition);
  }, [firstRender]);

  return null;
}

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import { Check, ChevronRight, Circle } from 'lucide-react';
import * as React from 'react';

export const MhiDropdownMenu = DropdownMenuPrimitive.Root;

export const MhiDropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

export const MhiDropdownMenuGroup = DropdownMenuPrimitive.Group;

export const MhiDropdownMenuPortal = DropdownMenuPrimitive.Portal;

export const MhiDropdownMenuSub = DropdownMenuPrimitive.Sub;

export const MhiDropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

export const MhiDropdownMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
    className?: string;
    inset?: boolean;
  }
>(({ children, className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.SubTrigger
    className={ classNames(
      'flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent data-[state=open]:bg-accent',
      inset && 'pl-8',
      className,
    ) }
    ref={ ref }
    { ...props }
  >
    { children }

    <ChevronRight className="ml-auto h-4 w-4" />
  </DropdownMenuPrimitive.SubTrigger>
));
MhiDropdownMenuSubTrigger.displayName
  = DropdownMenuPrimitive.SubTrigger.displayName;

export const MhiDropdownMenuSubContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent> & { className?: string }
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.SubContent
    className={ classNames(
      'z-card min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      className,
    ) }
    ref={ ref }
    { ...props }
  />
));
MhiDropdownMenuSubContent.displayName
  = DropdownMenuPrimitive.SubContent.displayName;

export const MhiDropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content> & { className?: string; sideOffset?: number }
>(({ className, sideOffset = 4, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      className={ classNames(
        'z-card min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      ) }
      ref={ ref }
      sideOffset={ sideOffset }
      { ...props }
    />
  </DropdownMenuPrimitive.Portal>
));
MhiDropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

export const MhiDropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    className?: string;
    inset?: boolean;
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    className={ classNames(
      'relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      inset && 'pl-8',
      className,
    ) }
    ref={ ref }
    { ...props }
  />
));
MhiDropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

export const MhiDropdownMenuCheckboxItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem> & { checked?: boolean; className?: string }
>(({ checked, children, className, ...props }, ref) => (
  <DropdownMenuPrimitive.CheckboxItem
    checked={ checked }
    className={ classNames(
      'relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className,
    ) }
    ref={ ref }
    { ...props }
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <Check className="h-4 w-4" />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>

    { children }
  </DropdownMenuPrimitive.CheckboxItem>
));
MhiDropdownMenuCheckboxItem.displayName
  = DropdownMenuPrimitive.CheckboxItem.displayName;

export const MhiDropdownMenuRadioItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem> & { className?: string }
>(({ children, className, ...props }, ref) => (
  <DropdownMenuPrimitive.RadioItem
    className={ classNames(
      'relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className,
    ) }
    ref={ ref }
    { ...props }
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <Circle className="h-2 w-2 fill-current" />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>

    { children }
  </DropdownMenuPrimitive.RadioItem>
));
MhiDropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;

export const MhiDropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    className?: string;
    inset?: boolean;
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Label
    className={ classNames(
      'px-2 py-1.5 text-sm font-semibold',
      inset && 'pl-8',
      className,
    ) }
    ref={ ref }
    { ...props }
  />
));
MhiDropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;

export const MhiDropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator> & { className?: string }
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Separator
    className={ classNames('-mx-1 my-1 h-px bg-muted', className) }
    ref={ ref }
    { ...props }
  />
));
MhiDropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

export function MhiDropdownMenuShortcut({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement> & { className?: string }) {
  return (
    <span
      className={ classNames('ml-auto text-xs tracking-widest opacity-60', className) }
      { ...props }
    />
  );
};
MhiDropdownMenuShortcut.displayName = 'DropdownMenuShortcut';


import type { DocumentReference } from 'firebase/firestore';

import { arrayRemove, arrayUnion, deleteField, documentId, getDocs, limit, orderBy, query, updateDoc, where } from 'firebase/firestore';

import type { ResultData } from '~~/app/result/result.entity';
import type { UserDoc } from '~~/app/user/user.entity';

import { getEarthNumbers } from '~~/app/result/result.calculation';
import { firebaseAuth } from '~~/firebase';
import { goalRepo, groupRepo, resultRepo } from '~~/firebase/repositories';
import { BaseRepository } from '~~/firebase/repositories/repository-factory';

import type { GoalData } from '../goals-groups/goal.entity';
import type { GroupData } from '../goals-groups/group.entity';

const BATCH_SIZE = 30;

export class UserRepository extends BaseRepository<UserDoc> {
  constructor() {
    super('users');
  }

  async addAcceptedAction(actionId: string) {
    const userRef = this.getDocRef(firebaseAuth.currentUser!.uid);
    return updateDoc(userRef, {
      acceptedActions: arrayUnion(actionId),
    });
  }

  async addDeclinedAction(actionId: string) {
    const userRef = this.getDocRef(firebaseAuth.currentUser!.uid);
    return updateDoc(userRef, {
      declinedActions: arrayUnion(actionId),
    });
  }

  async appendGoals(goalRef: DocumentReference) {
    const userRef = this.getDocRef(firebaseAuth.currentUser!.uid);
    return updateDoc(userRef, {
      goals: arrayUnion(goalRef),
    });
  }

  async appendGroups(groupRef: DocumentReference) {
    const userRef = this.getDocRef(firebaseAuth.currentUser!.uid);
    return updateDoc(userRef, {
      groups: arrayUnion(groupRef),
    });
  }

  async appendResults(results: ResultData) {
    await resultRepo.setDocument({
      data: {
        ...results,
        userRef: this.getDocRef(firebaseAuth.currentUser!.uid),
      },
      id: results.id,
    });

    const userRef = this.getDocRef(firebaseAuth.currentUser!.uid);
    const resultRef = resultRepo.getDocRef(results.id);

    return updateDoc(userRef, {
      results: arrayUnion(resultRef),
    });
  }

  async checkIfOwnAGroup() {
    const groups = await this.getGroups();
    return groups.some((group) => group.ownerRef.id === firebaseAuth.currentUser!.uid);
  }

  async deleteAcceptedAction(actionId: string) {
    const userRef = this.getDocRef(firebaseAuth.currentUser!.uid);
    await updateDoc(userRef, {
      acceptedActions: arrayRemove(actionId),
    });
  }

  async fetchCurrentUser(uid: string) {
    const user = await this.getDocument(uid);

    if (user) {
      const userResults = await this.getResults(user.results || []);
      const userGoals = await this.getGoals(user.goals || []);

      userResults.sort((a, b) => {
        return b.createdAt.seconds - a.createdAt.seconds;
      });

      userGoals.sort((a, b) => {
        return b.createdAt.seconds - a.createdAt.seconds;
      });

      user.results = userResults;
      user.goals = userGoals;
    }

    return user!;
  }

  async getAcceptedActions(limit?: number) {
    const userId = firebaseAuth.currentUser?.uid;

    if (!userId) {
      return null;
    }
    const acceptedActionsRefs = (await this.fetchCurrentUser(userId!)).acceptedActions;
    return acceptedActionsRefs.slice(0, limit ?? acceptedActionsRefs.length);
  }

  async getGoals(goals: GoalData[]) {
    if (goals.length === 0) {
      return [];
    }
    const goalsChunks = [];

    // Split the values array into chunks of 30 because of query limitations https://cloud.google.com/firestore/docs/query-data/queries#query_limitations
    for (let i = 0; i < goals.length; i += BATCH_SIZE) {
      goalsChunks.push(goals.slice(i, i + BATCH_SIZE));
    }

    const promises = goalsChunks.map(async (chunk) => {
      const q = query(goalRepo.collectionRef, where(documentId(), 'in', chunk));
      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    });

    const goalsQuery = await Promise.all(promises);
    return goalsQuery.flat();
  }

  async getGroups(limit?: number) {
    const userId = firebaseAuth.currentUser?.uid;
    const groupRefs = (await this.fetchCurrentUser(userId!)).groups;

    const groupsWithPromises = groupRefs.slice(0, limit ?? groupRefs.length).map((ref) =>
      groupRepo.getDocument(ref.id!).then((data) => ({ data, id: ref.id })),
    );

    const resolvedGroups = await Promise.all(groupsWithPromises);

    return await resolvedGroups.reduce(async (accumulator, currentValue) => {
      if (currentValue.data) {
        const { calculatedCurrentGroupResult, calculatedInitialGroupResult } = await groupRepo.getCalculatedGroupResult(currentValue.data.currentResult.memberResult as unknown as DocumentReference[], currentValue.data.initialResult.memberResult as unknown as DocumentReference[]);

        const resolvedAccumulator = await accumulator;

        resolvedAccumulator.push({
          ...currentValue.data,
          id: currentValue.id,
        });

        if (calculatedCurrentGroupResult) {
          currentValue.data.currentResult.earthsResult = getEarthNumbers(calculatedCurrentGroupResult);
        }

        if (calculatedInitialGroupResult) {
          currentValue.data.initialResult.earthsResult = getEarthNumbers(calculatedInitialGroupResult);
        }
      }
      return accumulator;
    }, Promise.resolve<GroupData[]>([]));
  }

  async getResult(userRef: DocumentReference, type: 'earliest' | 'latest') {
    const resultQuery = query(
      resultRepo.collectionRef,
      where('userRef', '==', userRef),
      orderBy('createdAt', type === 'latest' ? 'desc' : 'asc'),
      limit(1),
    );

    const resultDocs = await getDocs(resultQuery);

    if (resultDocs.docs.length < 1) {
      return null;
    };

    return {
      ...resultDocs.docs[0].data(),
      id: resultDocs.docs[0].id,
    };
  }

  async getResults(results: ResultData[]) {
    if (results.length === 0) {
      return [];
    }

    const resultChunks = [];

    // Split the values array into chunks of 30 because of query limitations https://cloud.google.com/firestore/docs/query-data/queries#query_limitations
    for (let i = 0; i < results.length; i += BATCH_SIZE) {
      resultChunks.push(results.slice(i, i + BATCH_SIZE));
    }

    const promises = resultChunks.map(async (chunk) => {
      const q = query(resultRepo.collectionRef, where(documentId(), 'in', chunk));
      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => doc.data());
    });

    const resultsQuery = await Promise.all(promises);
    return resultsQuery.flat();
  }

  async updateNewsletter(type: 'subscribe' | 'unsubscribe', newsletterRef?: DocumentReference) {
    const userRef = this.getDocRef(firebaseAuth.currentUser!.uid);

    if (type === 'subscribe') {
      return updateDoc(userRef, {
        newsletter: newsletterRef,
      });
    } else {
      return updateDoc(userRef, {
        newsletter: deleteField(),
      });
    }
  };

  async updateUserProfile(name: string, photoUrl: string) {
    const userRef = this.getDocRef(firebaseAuth.currentUser!.uid);

    if (firebaseAuth.currentUser) {
      await updateDoc(userRef, {
        name,
        photoUrl,
      });
    }
  }
}
